import { SubscriptionFormModalAction } from '@liftfoils/models'
import { breakpoints, styled, useMediaQuery } from '@liftfoils/styles'
import dynamic from 'next/dynamic'
import { FC, ReactNode, useState } from 'react'
import { Modal } from '../../../Modal/Modal'
import { Button } from '../../../Button/Button'
import { Close } from '@liftfoils/icons'

const DynamicSubscriptionModalContent = dynamic(
  // @ts-ignore
  () =>
    import('./SubscriptionModalContent').then(
      (mod) => mod.SubscriptionModalContent,
    ),
  { ssr: false },
)

type ActionProps = {
  onClick?: () => void
}

type SubscriptionModalProps = {
  children?: ReactNode
  action: SubscriptionFormModalAction
  renderButton: (actionProps: ActionProps) => JSX.Element
}

const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  width: '$11',
  height: '$11',
  display: 'grid',
  placeItems: 'center',
})

export const SubscriptionModal: FC<SubscriptionModalProps> = ({
  action,
  renderButton,
}) => {
  const matchesMd = useMediaQuery(breakpoints.md)
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)

  const actionProps = {
    onClick: () => toggleModal(),
  }

  return (
    <>
      {renderButton(actionProps)}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        position={matchesMd ? 'center' : 'bottom'}
        size={matchesMd ? 'intrinsic' : 'stretch-x'}
        variant={matchesMd ? 'white-rounded' : 'default'}
      >
        <CloseButtonWrapper>
          <Button onClick={() => setIsOpen(false)} appearance={'flex'}>
            <Close />
          </Button>
        </CloseButtonWrapper>
        <DynamicSubscriptionModalContent
          action={action}
          onClose={toggleModal}
        />
      </Modal>
    </>
  )
}
