import { Action } from '@liftfoils/models'
import { FC, PropsWithChildren } from 'react'
import { StoryModal } from './LinkActionComponents/StoryModal/StoryModal'
import { YoutubeModal } from './LinkActionComponents/YoutubeModal/YoutubeModal'
import { logger } from '@liftfoils/utils'
import { useAssistanceContext } from '@liftfoils/assistance'
import { useGearSelectorContext } from '@liftfoils/gear-selector-provider'
import { SubscriptionModal } from './LinkActionComponents/SubscriptionModal/SubscriptionModal'

type ActionProps = {
  onClick?: () => void
}

type LinkActionProps = {
  action: Action
  className?: string
  renderButton: (actionProps: ActionProps) => JSX.Element
}

export const LinkAction: FC<PropsWithChildren<LinkActionProps>> = ({
  action,
  renderButton,
  ...props
}) => {
  const { openAssistanceModal } = useAssistanceContext()
  const { openModal } = useGearSelectorContext()

  switch (action.type) {
    case 'storyModal':
      return (
        <StoryModal renderButton={renderButton} action={action} {...props} />
      )
    case 'youtubeModal':
      return (
        <YoutubeModal renderButton={renderButton} action={action} {...props} />
      )
    case 'assistanceModal': {
      const actionProps = {
        onClick: () => openAssistanceModal(),
      }
      return <>{renderButton(actionProps)}</>
    }
    case 'classicFoilGearSelectorModal': {
      const actionProps = {
        onClick: () => openModal(),
      }
      return <>{renderButton(actionProps)}</>
    }
    case 'eFoilGearSelectorModal': {
      const actionProps = {
        onClick: () => openModal(),
      }
      return <>{renderButton(actionProps)}</>
    }
    case 'subscriptionFormModal':
      return <SubscriptionModal action={action} renderButton={renderButton} />
    default:
      logger.error(
        `[LinkAction] There is no defined actionType${
          (action as Action)?.type && `: ${(action as Action).type}`
        }`,
      )
      return <h3>There is no defined actionType.</h3>
  }
}
