import {
  DEFAULT_LOCALE_ID,
  LocaleId,
  ROUTE_BASE_PRODUCTS,
} from '@liftfoils/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

import { createMediaProjection } from './createMediaProjection'

export const createLinkProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
    linkType,
    linkType == 'internal' && defined(internal) => internal -> {
      "href": select(
        _type == 'product' => '${ROUTE_BASE_PRODUCTS}/',
        _type == 'efoil' => '${ROUTE_BASE_PRODUCTS}/',
        ''
      ) + coalesce(store.slug.current, mainProduct->store.slug.current, path.current, '') + coalesce(^.query, '')
    },

    linkType == 'external' => {
      "href": coalesce(external.${localeId}, external.${defaultLocaleId})
    },
    linkType == 'phone' => {
      "href": "tel:" + coalesce(phone.${localeId}, phone.${defaultLocaleId})
    },
    linkType == 'email' => {
      "href": "mailto:" + coalesce(email.${localeId}, email.${defaultLocaleId})
    },
    linkType == 'file' => {
      "href": file.asset->url + "?dl=",
      "download": true,
    },
    linkType == 'linkAction' => {
      "action": {
        "type": linkAction.linkActionType,
        "payload": {
          linkAction.linkActionType == "storyModal" => {
            "storyId": linkAction.storyModal.story._ref
          },
          linkAction.linkActionType == "youtubeModal" => {
            "youtubeUrl": linkAction.youtubeModal.youtubeUrl
          },
          linkAction.linkActionType == "subscriptionFormModal" => {
            ${coalesceLocaleField(
              'linkAction.subscriptionFormModal.heading',
              localeId,
              'heading',
            )},
            ${coalesceLocaleField(
              'linkAction.subscriptionFormModal.description',
              localeId,
              'description',
            )},
            "media": linkAction.subscriptionFormModal.media${MEDIA_PROJECTION},
          }
        }
      }
    }
  }`
}
